'use strict';

var timeTo24h = require('./timeTo24h');

var days = [
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday',
	'sunday',
];

function daysTo24h (o) {
	var rtn = {};
	days.forEach(function (day) {
		rtn[day] = {
			open: o[day].open,
			from: timeTo24h(o[day].from),
			to: timeTo24h(o[day].to),
		};
	});
	return rtn;
}

module.exports = daysTo24h;
