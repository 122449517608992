const _ = require('lodash');

const findMe = require('../../lib/findMe');
const locationStore = require('../../lib/locationAPIWrapper');

/* global $, google */
$(function () {
	var marker;
	window.infowindow = null;
	window.selectedLocation;

	function getLabel (location, currentLocationLat, currentLocationLng) {
		var holidayHoursString = (location.holidayHoursArray.length > 0) ? (
			'<div class="loc-summary__subtitle">Holiday hours: </div>'
			+ '<ul class="loc-summary__hols">'
			+ 	'<li>' + location.holidayHoursArray.join('<br />') + '</li>'
			+ '</ul>'
			) : '';

		if (TARGET_BRAND === 'goget') {
			holidayHoursString = '';
			// holidayHoursString = '<div class="loc-summary__subtitle">Holiday hours: </div>'
			// 	+ '<ul class="loc-summary__hols">'
			// 	+ 	'<li>*Store opening times may vary due to Christmas Holidays</li>'
			// 	+ '</ul>';
		}

		if (TARGET_BRAND === 'tnt') {
			holidayHoursString = '';
		}

		var currentPlace = $('#pe-search__input').val();

		var productsAndServicesInfo = '';

		if (TARGET_BRAND === 'blueshyft') {
			productsAndServicesInfo = (
				'<div class="loc-summary__subtitle">Products &amp; Services available:</div>'
			+	'<div class="loc-summary__services">'
			+		(location.bauIsEnabled ? '<div class="loc-summary__service"><a href="http://www.bitcoin.com.au" target="_blank"><img src="/images/logo_bau.png" class="loc-summary__services__image" /></a></div>' : '')
			+		(location.coinjarIsEnabled ? '<div class="loc-summary__service"><a href="https://www.coinjar.com.au" target="_blank"><img src="/images/logo_coinjar.png" class="loc-summary__services__image" /></a></div>' : '')
			+		(location.coinspotIsEnabled ? '<div class="loc-summary__service"><a href="https://www.coinspot.com.au" target="_blank"><img src="/images/logo_coinspot.png" class="loc-summary__services__image" /></a></div>' : '')
			+		(location.coinloftIsEnabled ? '<div class="loc-summary__service"><a href="https://www.coinloft.com.au" target="_blank"><img src="/images/logo_coinloft.png" class="loc-summary__services__image" /></a></div>' : '')
			+		(location.libertyxIsEnabled ? '<div class="loc-summary__service"><a href="https://libertyx.com" target="_blank"><img src="/images/logo_libertyx.png" class="loc-summary__services__image" /></a></div>' : '')
			+		(location.bauIsEnabled || location.coinjarIsEnabled || location.coinspotIsEnabled || location.coinloftIsEnabled || location.libertyxIsEnabled ? '<span class="service_descr">Buy Bitcoin, Ethereum & other digital currencies using cash in-store.</span>' : '')
			+ 		(location.ladbrokesIsEnabled ? '<div class="loc-summary__service"><a href="http://www.ladbrokes.com.au" target="_blank"><img src="/images/logo_ladbrokes.png" class="loc-summary__services__image" /></a></div>' : '')
			+ 		(location.buxIsEnabled ? '<div class="loc-summary__service"><a href="http://www.bux.com" target="_blank"><img src="/images/logo_bux.png" class="loc-summary__services__image" /></a></div>' : '')
			+		(location.ladbrokesIsEnabled || location.buxIsEnabled ? '<span class="service_descr">Deposit cash directly into your wagering or money transfer mobile wallet.</span>' : '')
			+		(location.tntIsEnabled ? '<div class="loc-summary__service"><a href="http://www.tnt.com.au" target="_blank"><img src="/images/logo_tnt.png" class="loc-summary__services__image" /></a></div>' : '')
			+		(location.tollIsEnabled ? '<div class="loc-summary__service"><a href="https://www.tollgroup.com" target="_blank"><img src="/images/logo_toll.png" class="loc-summary__services__image" /></a></div>' : '')
			+		(location.mylotto24IsEnabled ? '<div class="loc-summary__service"><a href="https://mylotto24.com.au" target="_blank"><img src="/images/logo_mylotto24.png" class="loc-summary__services__image" /></a></div>' : '')
			+		(location.tntIsEnabled || location.tollIsEnabled ? '<span class="service_descr">Collect your not-at-home & direct deliveries, and return faulty goods.</span>' : '')
			+		(location.newscorpIsEnabled ? '<div class="loc-summary__service"><a href="http://www.newscorpaustralia.com" target="_blank"><img src="/images/logo_newscorp.png" class="loc-summary__services__image" /></a></div>' : '')
			+		(location.mmgIsEnabled ? '<div class="loc-summary__service"><a href="www.mmg.com.au" target="_blank"><img src="/images/logo_mmg.png" class="loc-summary__services__image" /></a></div>' : '')
			+		(location.newscorpIsEnabled || location.mmgIsEnabled ? '<span class="service_descr">Perform a number of paper subscription services in-store.</span>' : '')
			+		(location.sodakingIsEnabled ? '<div class="loc-summary__service"><a href="http://www.sodaking.com.au" target="_blank"><img src="/images/logo_sodaking.png" class="loc-summary__services__image" /></a></div><span class="service_descr">Swap your empty soda cylinders in-store for a new, full one at a discount.</span>' : '')
			+		(location.alipayIsEnabled ? '<div class="loc-summary__service"><a href="https://intl.alipay.com" target="_blank"><img src="/images/logo_alipay.png" class="loc-summary__services__image" /></a></div><span class="service_descr">Purchase any goods in-store using funds in your Alipay digital wallet.</span>' : '')
			+		(location.moneytechIsEnabled ? '<div class="loc-summary__service"><a href="http://bpay.com.au" target="_blank"><img src="/images/logo_bpay.png" class="loc-summary__services__image" /></a></div><span class="service_descr">Pay any bill with the BPAY logo on it using cash over-the-counter.</span>' : '')
			+		(location.gogetIsEnabled ? '<div class="loc-summary__service"><a href="https://www.goget.com.au" target="_blank"><img src="/images/logo_goget.png" class="loc-summary__services__image" /></a></div><span class="service_descr">Pick-up your driver pack & activate your account to drive a share-car.</span>' : '')
			+	'</div>'
			);
		}


		if (TARGET_BRAND === 'sodaking' && location.sodakingIsEnabled) {
			productsAndServicesInfo = (
				'<div class="loc-summary__subtitle">Products &amp; Services available:</div>'
				+	'<div class="loc-summary__services">'
				+	`<div class="loc-summary__service"><a href="http://www.sodaking.com.au" target="_blank"><img src="/images/logo_sodaking.png" class="loc-summary__services__image" /></a><span class="loc-summary__service__text">Purchase or exchange a soda gas cylinder<br>Stock level: ${location.exchangeOnHand}</span></div>`
				+	'</div>'
			);
		}

		var validatedAddress;
		if (location.validatedAddress) {
			var l = location.validatedAddress;
			validatedAddress = `${l.number ? `${l.number}, ` : ''} ${l.name ? `${l.name}, ` : ''} ${l.street1}, ${l.street2 ? `${l.street2}, ` : ''} ${l.suburb} ${l.state} ${l.postcode}`;
		}

		return (
			'<div class="info-window">'
			+	'<div class="loc-summary">'
			+		'<div class="loc-summary__title">'
			+			(TARGET_BRAND === 'blueshyft'
						? `<a href="/newsagency/${location.shopId}?${currentPlace && 'place=' + currentPlace + '&'}lat=${currentLocationLat}&lng=${currentLocationLng}" class="loc-summary__title__link">${location.shopName}</a>`
						: `${location.shopName ? location.shopName : location.name}`)
			+		'</div>'
			+		'<div class="loc-summary__byline">' + validatedAddress + '</div>'
			+	 	'<div class="loc-summary__subtitle">Opening hours: </div>'
			+	 	'<ul class="loc-summary__list">'
			+	 		'<li><span class="day">Mon:</span><span class="time">' + location.openingHours.monday.value + '</span></li>'
			+	 		'<li><span class="day">Tue:</span><span class="time">' + location.openingHours.tuesday.value + '</span></li>'
			+	 		'<li><span class="day">Wed:</span><span class="time">' + location.openingHours.wednesday.value + '</span></li>'
			+	 		'<li><span class="day">Thu:</span><span class="time">' + location.openingHours.thursday.value + '</span></li>'
			+	 		'<li><span class="day">Fri:</span><span class="time">' + location.openingHours.friday.value + '</span></li>'
			+	 		'<li><span class="day">Sat:</span><span class="time">' + location.openingHours.saturday.value + '</span></li>'
			+	 		'<li><span class="day">Sun:</span><span class="time">' + location.openingHours.sunday.value + '</span></li>'
			+	 	'</ul>'
			+ 		holidayHoursString
			+ 		'<div>' + productsAndServicesInfo + '</div>'
			+	 '</div>'
			+ '</div>'
		);
	};

	/* Get the value of a querystring */
	var getQueryString = function (field, url) {
		var href = url ? url : window.location.href;
		var reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
		var string = reg.exec(href);
		return string ? string[1] : null;
	};

	var urlPlace = decodeURIComponent(getQueryString('place'));
	var urlPlaceLat = Number(decodeURIComponent(getQueryString('lat')));
	var urlPlaceLng = Number(decodeURIComponent(getQueryString('lng')));

	// Show the start UI if no url query detected
	if (urlPlace === 'null' && !urlPlaceLat && !urlPlaceLng) {
		$('.pe-wrap').removeClass('pe-wrap-started').addClass('pe-wrap-start');
	} else {
		$('.pe-wrap').removeClass('pe-wrap-start').addClass('pe-wrap-started');
		$('#pe-search__input').val(urlPlace !== 'null' ? urlPlace : '');
	}

	// Google map
	function initialize () {

		var markers = [];
		var markersInBounds = [];

		var currentLocationLat = urlPlaceLat;
		var currentLocationLng = urlPlaceLng;

		var mapStyle = [
			{
				'featureType': 'transit',
				'stylers': [
					{ 'visibility': 'off' },
				],
			},
			{
				'featureType': 'poi',
				'stylers': [
					{ 'visibility': 'off' },
				],
			},
		];

		var map = window.map = new google.maps.Map(document.getElementById('pe-map'), {
			disableDefaultUI: true,
			disableDoubleClickZoom: false,
			draggable: true,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			scrollwheel: true,
			styles: mapStyle,
			zoomControl: true,
		});

		map.addListener('click', function () {
			if (window.infowindow) {
				window.infowindow.close();
			}
		});

		if (urlPlaceLng && urlPlaceLat) {
			var initialLocation = new google.maps.LatLng(urlPlaceLat, urlPlaceLng);
			map.setCenter(initialLocation);
			setTimeout(() => map.setZoom(15), 2000);
			if ($(window).width() < 991) {
				$('.pe-search__result').append('<div class="pe-results"><ul id="newsagency-list"></ul><div class="locations-loader"><span class="loading loading--blue"></span></div></div>');
				$('.pe-search__result').addClass('hidden');
			} else {
				$('.pe-search__result').append('<div class="pe-results"><ul id="newsagency-list"></ul><div class="locations-loader"><span class="loading loading--blue"></span></div></div>');
			}
		} else {
			var defaultBounds = new google.maps.LatLngBounds(
				new google.maps.LatLng(-33.8902, 151.1759),
				new google.maps.LatLng(-33.8474, 151.2631)
			);

			map.fitBounds(defaultBounds);
		}

		// Getting the all locations on initial app load
		locationStore.getLocations(PARTNER_API_BASE_URL, function (err, locations) {
			if (err) console.error('Error loading locations', err, err.stack);
			locations.forEach(function (location) {
				var flattenedArray = _.flatten(location);

				if (flattenedArray[4] && flattenedArray[3]) {
					marker = new google.maps.Marker({
						location: {
							shopId: flattenedArray[0],
							shopName: flattenedArray[1],
							address: flattenedArray[2],
						},
						map: map,
						position: { 'lat': flattenedArray[4], 'lng': flattenedArray[3] },
					});
					markers.push(marker);

					marker.addListener('click', function () {
						window.selectedLocation = this.location.shopId;
						$("li[location-id='" + this.location.shopId + "'] > a").addClass('active');

						locationStore.getLocationDetails(PARTNER_API_BASE_URL, this.location.shopId, function (err, location) {
							var contentString = getLabel(location, currentLocationLat, currentLocationLng);

							if (window.infowindow) {
								window.infowindow.close();
							}

							window.infowindow = new google.maps.InfoWindow({
								content: contentString,
							});

							var center = this.getPosition();

							map.panTo(center);
							map.panBy(0, -200);

							window.infowindow.open(map, this);
						}.bind(this));
					});
				}
			});
		});

		var input = /** @type {HTMLInputElement} */(
		document.getElementById('pe-search__input'));

		var options = {
			types: ['(regions)'],
			componentRestrictions: { country: 'au' },
		};

		var searchBox = window.searchBox = new google.maps.places.Autocomplete(input, options);

		google.maps.event.addListener(searchBox, 'place_changed', function () {
			$('.pe-search__result').addClass('hidden');

			var place = searchBox.getPlace();

			// Hide the initial search overlay when performing the search
			$('.pe-wrap').removeClass('pe-wrap-start').addClass('pe-wrap-started');

			// Clear out previous search results
			$('.pe-search__result').html('');

			// Rebuild the search results list
			if ($(window).width() < 991) {
				$('.pe-search__result').append('<div class="pe-results"><ul id="newsagency-list"></ul><div class="locations-loader"><span class="loading loading--blue"></span></div></div>');
				$('.pe-search__result').addClass('hidden');
			} else {
				$('.pe-search__result').append('<div class="pe-results"><ul id="newsagency-list"></ul><div class="locations-loader"><span class="loading loading--blue"></span></div></div>');
			}

			if (place.length === 0) {
				return;
			}
			// For each place, get the icon, place name, and location.
			var bounds = new google.maps.LatLngBounds();

			bounds.extend(place.geometry.location);

			// Zooming out from the default zoom after a location is selected in the search box
			if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
				var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
				var extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
				bounds.extend(extendPoint1);
				bounds.extend(extendPoint2);
			}

			map.fitBounds(bounds);
		});

		google.maps.event.addListener(map, 'bounds_changed', function () {

			var bounds = map.getBounds();
			searchBox.setBounds(bounds);

			var currentMapCenter = map.getCenter();

			if (currentMapCenter) {
				currentLocationLat = currentMapCenter.lat();
				currentLocationLng = currentMapCenter.lng();
			}

			$('#newsagency-list').html('');

			if ($(window).width() > 991) {
				$('.pe-search__result').removeClass('hidden');
			}

			if (bounds) {
				$('.locations-loader').addClass('hidden');
				markersInBounds = _.filter(markers, function (marker) {
					if (bounds.contains(marker.getPosition())) {
						var selectedMarker = (marker.location.shopId === window.selectedLocation) ? ' pe-result__link--active' : '';
						$('#newsagency-list').append(
								'<li location-id="' + marker.location.shopId + '"><a href="javascript:;" class="pe-result__link' + selectedMarker + '"><div class="icon"><span class="ion-ios-location"></span></div><div class="name">' + marker.location.shopName + '</div><div class="address">' + marker.location.address + '</div></a></li>'
							);
						return marker;
					}
				});

				if (markersInBounds.length < 1) {
					var zoomLevel = map.getZoom();
					if (zoomLevel <= 7) return;
					map.setZoom(zoomLevel - 1);
				}
			}

			$("li[location-id='" + window.selectedLocation + "']").addClass('active');

			$('li[location-id]').click(function () {
				var shopId = this.getAttribute('location-id');

				if ($(window).width() < 991) {
					$('.pe-search__result').toggleClass('hidden');
					$('#toggle-icon').toggleClass('ion-ios-location').toggleClass('ion-navicon');
					$('.pe-filter-bar').removeClass('active');
					$('.pe-map-wrap').toggleClass('hidden');
				}

				var marker = _.find(markersInBounds, function (m) {
					return shopId === m.location.shopId;
				});

				if (!marker) return;

				locationStore.getLocationDetails(PARTNER_API_BASE_URL, shopId, function (err, location) {
					var contentString = getLabel(location, currentLocationLat, currentLocationLng);

					if (window.infowindow) {
						window.infowindow.close();
					}

					window.infowindow = new google.maps.InfoWindow({
						content: contentString,
					});

					var center = marker.getPosition();

					window.selectedLocation = marker.location.shopId;

					map.panTo(center);
					map.panBy(0, -200);

					window.infowindow.open(map, marker);
				});
			});
		});

		// Perform the locate me
		$('.pe-search__button').click(function () {
			$('#pe-search__input').val('');
			$('#pe-search__input').attr('disabled', 'disabled');
			$('#pe-search__input').attr('placeholder', 'Locating you...');
			$('.ion-navigate').removeClass('ion-navigate').addClass('loading');

			findMe(map, function () {
				$('.pe-wrap').removeClass('pe-wrap-start').addClass('pe-wrap-started');
				$('#button-icon').removeClass('loading');
				$('#pe-search__input').removeAttr('disabled', 'disabled');
				$('#pe-search__input').removeAttr('placeholder', 'Locating you...');
				$('#button-icon').addClass('ion-navigate');
				$('#pe-search__input').attr('placeholder', 'Enter suburb or postcode...');
			});

		});

		$(window).resize(function () {
			if ($(window).width() < 991) {
				$('.pe-search__result').addClass('hidden');
				$('.pe-filter-bar').removeClass('active');
				$('.pe-map-wrap').removeClass('hidden');
			}
		});

		$('.toggle-view').click(function () {
			$('.pe-search__result').toggleClass('hidden');
			$('.pe-filter-bar').toggleClass('active');
			$('.pe-map-wrap').toggleClass('hidden');
		});


		// Show the cancel button when performing the search
		$('#pe-search__input').keypress(function () {
			if (this.value.length >= 0) {
				$('.pe-search__button').addClass('hidden');
				$('#icon-close').removeClass('hidden');

				if ($('.pe-filter-bar').hasClass('active')) {
					$('.pe-filter-bar').removeClass('active');
				}

				if ($('.pe-map-wrap').hasClass('hidden')) {
					$('.pe-map-wrap').removeClass('hidden');
				}

			} else {
				$('#icon-close').addClass('hidden');
			}
		});

		// Clear the search value when click on cancel
		$('#icon-close').click(function () {
			$('#pe-search__input').val('').focus();
			// $('.pe-search__result').addClass('hidden');
			$('#icon-close').addClass('hidden');
			$('.pe-search__button').removeClass('hidden');
			$('.pe-box').html('');
			if (window.infowindow) {
				window.infowindow.close();
			}
		});

		$('.pe-search__button').click(function () {
			if (!$('.pe-results').length) {
				$('.pe-search__result').append('<div class="pe-results"><ul id="newsagency-list"></ul></div>');
				$('.pe-search__result').addClass('hidden');
				if ($(window).width() > 991) {
					$('.pe-filter-bar').toggleClass('active');
				}

			}
		});

		if (!('ontouchstart' in window)) {
			$('[data-toggle="tooltip"]').tooltip();
		}
	}

	$(document).ready(function () {
		google.maps.event.addDomListener(window, 'load', initialize());
	});
	jQuery.readyException = function (error) {
		console.log('error --->', error);
		// "error" is thrown from any ready handler
	};

});
