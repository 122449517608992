'use strict';

function timeTo24h (n) {
	if (!n) return '00:00';
	var m = n % 60;
	var h = (n - m) / 60;
	if (!m) m = '00';
	else if (m < 10) m = '0' + m;
	if (h < 10) h = '0' + h;
	return h + ':' + m;
}

module.exports = timeTo24h;
