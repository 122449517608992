'use strict';

const fetch = require('universal-fetch');
const titleize = require('titleize');

const daysTo24h = require('./daysTo24h');

const headers = new Headers({
	'Accept': 'application/json',
	'Content-Type': 'application/json',
});


// Load all locations
function getLocations (api, callback) {
  const _headers = TARGET_BRAND === 'goget' ? {} : { headers: headers};
  return fetch(`${api}/locations/explorer`, _headers)
		.then(res => res.json())
		.then(data => {
			if (data.locations) callback(null, data.locations);
		})
		.catch(callback);
}

// Load all locations dependent on ShopId
function getLocationDetails(api, shopId, callback) {
	let _headers = TARGET_BRAND === 'goget' ? {} : {headers: headers};
	const url = `${api}/locations/explorer/${shopId}`;
	return fetch(url, _headers)
		.then(res => res.json())
		.then(data => {
			if (data.location) {
				// Format the open hrs several different ways
				let openingHours = data.location.openingHours;
				let openingHours24 = daysTo24h(openingHours);

				data.location.openingHours = Object.keys(openingHours).reduce((a, name) => {
					const day = openingHours24[name];
					day.label = name.slice(0, 1).toUpperCase() + name.slice(1);
					day.value = day.open ? (day.from + ' - ' + day.to) : 'Closed';
					a[name] = day;
					return a;
				}, {});

				// Create an array of holiday days
				data.location.holidayHoursArray = (data.location.holidayHours ? titleize(data.location.holidayHours).split(/\n/g) : []);

				// console.log('Returning data.location', data.location);
				return callback(null, data.location);
			}
		})
		.catch((err) => {
			console.log(err);
			return callback(err);
		});
}

module.exports = {
	getLocations: getLocations,
	getLocationDetails: getLocationDetails,
};
