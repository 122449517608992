/* global $, google */

'use strict';

var browserSupportFlag;
var initialLocation;

function geoLocation (map, next) {
	if (navigator.geolocation && navigator.geolocation.getCurrentPosition) {
		browserSupportFlag = true;
		navigator.geolocation.getCurrentPosition(function (position) {
			initialLocation = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
			map.setCenter(initialLocation);
			map.setZoom(14);
			next();
		}, function (err) {
			console.error('Error:', err.stack);
			handleNoGeolocation(browserSupportFlag);
		});
	} else {
		browserSupportFlag = false;
		handleNoGeolocation(browserSupportFlag);
	}
}

function handleNoGeolocation (errorFlag) {
	// remove searching styles
	$('#pe-search__input').attr('disabled', false);
	$('#pe-search__input').attr('placeholder', 'Suburb or postcode...');
	$('#button-icon').removeClass('loading').addClass('ion-navigate');

	if (errorFlag) {
		alert('Geolocation service failed.');
	} else {
		alert("Your browser doesn't support geolocation.");
	}
}


module.exports = geoLocation;
